import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import {
  Grid
} from '@material-ui/core';
import CoursesContent from '../components/Courses/CoursesContent'
import Video from '../components/Video';

const Walkthrough = () => {
    return (
        <Layout>
          <Navbar />
            <Grid container direction='column' alignItems='center' style={{marginTop: 150, width: '100%'}}>
            <h2>Outspoke Walkthrough</h2><br/>
            </Grid>
            <Grid container direction='column' alignItems='center' style={{height: 420, marginTop: 10, width: '100%'}}>
              <Video
                videoSrcURL="https://s3.us-west-1.amazonaws.com/outspoke-images/Outspoke_Walkthrough.mp4"
                videoTitle='Outspoke Walkthrough'
                />
            </Grid>
        </Layout>
    );
}

export default Walkthrough;
